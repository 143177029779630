const en = {
	header: {
		logo: "Logo",
		router: {
			title: "Yoxi",
			publish: "OFFICIAL RELEASE",
			about: "ABOUT YXC",
			info: "INFORMATION",
			project: 'PROJECT PROJRESS',
			language: 'Language',
			plan: 'PROJECT PLANNING',
		}
	},
	download: {
		pc: {
			download: 'download app client',
			title: 'manage your digital assets at any time',
			bnt1: 'IOS',
			bnt2: 'Android',
			qrcode: 'scan QR code to download app',
			tutorial: 'IOS user installation tutorial',
			title1: 'step 1',
			Subheading1: 'download app',
			content1: 'turn on the setting function in the phone, and select "universal',
			title2: 'step 2',
			Subheading2: 'device management',
			content2: 'in general, select "describe file and device management" function',
			title3: 'step 3',
			Subheading3: 'select Install',
			content3: 'in the enterprise application classification of description file and device management, select the file name of the enterprise application to be installed (consistent with the prompt when opening),click Enter ',
			title4: 'step 4',
			Subheading4: 'set trust',
			content4: 'after entering the enterprise signature page, confirm the company name and application name in the enterprise signature, click trust "enterprise signature company name',
			title5: 'step 5',
			Subheading5: 'start app',
			content5: 'go back to the desktop and open the app again. ',
		}
	},
	user: {
		login: "Sign in",
		register: "Sign up",
		hasCount: "Already have an account?",
		noCount: "New user?",
		logOut: "Sign out",
		logSucc: "Sign in Success",
		place: {
			userName: "Please enter a user name",
			passWord: "Please enter your password",
			passWordAgain: "Please enter your password again",
			passNotMatch: "The two passwords do not match",
			leader:'Please enter the inviter',
			Agreement:'User protocol',
			agreeAgreement:'Please agree to the user agreement first'
		}
	},
	mnemonic:{
		myWord:"Mnemonic",
		hint:"Please copy it accurately on paper and there is a safe place only you know.",
		next:"NEXT",
		backup:"Backup Mnemonic",
		hint2:"Please click on your mnemonic in order to make sure that the backup mnemonic is correct",
		clean:"Clean up",
		select:"Please select",
		finish:"Finished",
		notify1:"Wrong choice of MNEMONIC, please try again!!",
		notify2:"MNEMONIC backup complete, please download the APP!"
	},
	code: {
		error: "error"
	},
	expression:{
		regSuccess:'Registration is successful, please download app for the next step!',
		swipetext1:'The rise and benefits of many traditional business giants are created by users',
		swipetext2:'But users can\'t share the benefits',
		swipetext3:'In the future, the era of rising because of users but no benefits will be over',
		swipetext4:'---Vision from Yoxi Ecology',
		videoIntro: 'Video Introduction',
		
		infoText1: 'Blockchain information channel takes you to know what blockchain is, reports the latest blockchain industry news, the latest blockchain information and the latest blockchain information trends for you.',
		
		aboutText1: 'In the era of rapid development of the Internet, constant reform and innovation have been carried out. Commercial giants have occupied users and achieved great profits, but users cannot participate in the profits. This is a very unfair phenomenon!',
		aboutText2: 'YXC Foundation Ltd in Singapore is committed to building the largest Yoxi app landing ecosystem based on the blockchain! It will realize the rise of users and create a new era for the substantial landing of blockchain!',
		aboutText3: 'There are 52 technical team members of YXC foundation and Yoxi project, most of them are from former Alibaba and Baidu engineers, with complete experience in development and implementation.',
		aboutText4: 'YXC is used as a token in Yoxi app ecology. It can be used through video, community, social networking, mining, voting, reward, project incubation, payment and other scenarios. Users can invite new users to participate in future earnings.',
		aboutText5: 'When users use all parts of the app, such as publishing original videos, community publishing articles, etc., the users who are liked to praise or be rewarded will generate revenue! Let users realize the equal value of contribution and contribution.',
		aboutText6: 'It will realize the rise of users and share profits, and create a new era of substantial landing.',
		
		imageText: 'Ecological Advantages Of Yoxi',
		imageText1: 'A platform of our own. Everyone is the master of the platform,',
		imageText2: 'If you give, you will get the return - you can get the benefit of this platform through invitation!',
		
		planText1: '2021 to be continued...',
		
		mediaText1: 'Have the ability to spread fire all over the world',
		mediaText2: 'Full coverage of domestic and overseas media, accurate and efficient publicity',
		
		downloadText1: 'White Paper Download',
		downloadText2: 'App Download',
		
		downloadText: 'This is a conceptual document ("White Paper") that relates to the concept of an e- commerce chain (LUCY) platform and its LUCY mark. Modification or replacement can be made at any time. However, the LUCY is not obliged to update the white paper or provide the recipient with access to any additional information.',
		
		year: 'Y',
		month: 'M',
		projectText: 'Please wait while the project progresses',
		projectText1: 'Progress in January',
		projectText2: 'Progress in February',
		projectText3: 'Progress in March',
		projectText4: 'Progress in April',
		projectText5: 'Progress in May',
		projectText6: 'Progress in June',
		projectText7: 'Progress in July',
		projectText8: 'Progress in August',
		projectText9: 'Progress in September',
		projectText10: 'Progress in October',
		projectText11: 'Progress in November',
		projectText12: 'Progress in December',
		
	},
	home:{
		'下载白皮书':'Download the White Paper',
		'新一代电子商务':'New generation of electronic commerce',
		'核心团队和贡献者':'Core teams and contributors',
		'免责声明':'Core teams and contributors',
		
	}
};
export default en;
