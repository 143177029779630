import Vue from 'vue'
import App from './App.vue'
import {
	router
} from './router';

import i18n from "./i18n/index";
import './rem.js';
import {
	Button,
	Row,
	Col,
	Field,
	NavBar,
	Image,
	Popup,
	Picker,
	Checkbox,
	CheckboxGroup,
	Notify,
	Swipe,
	SwipeItem,
	Divider,
	ActionSheet,
	Tag,
	Tab,
	Tabs

} from 'vant';


Vue.config.productionTip = false

Vue.use(Button);
Vue.use(Row).use(Col);
Vue.use(Field); //输入框
Vue.use(NavBar);
Vue.use(Image);
Vue.use(Picker); //Picker 选择器
Vue.use(Popup); //Popup 弹出层
Vue.use(Notify); //Notify 消息提示
Vue.use(Checkbox).use(CheckboxGroup); //Checkbox 复选框
Vue.use(Swipe).use(SwipeItem); //Swipe 轮播
Vue.use(Divider); //Divider 分割线
Vue.use(ActionSheet); //ActionSheet 上拉菜单
Vue.use(Tag); //Tag 标记
Vue.use(Tab).use(Tabs); //Tab 标签页

import { Image as VanImage } from 'vant';
Vue.use(VanImage);

import { PullRefresh } from 'vant';
Vue.use(PullRefresh);

import { List } from 'vant';
Vue.use(List);

import { Toast } from 'vant';
Vue.use(Toast);

import VueAwesomeSwiper from 'vue-awesome-swiper'
// require styles
import 'swiper/dist/css/swiper.css'
Vue.use(VueAwesomeSwiper)

//高德地图
import VueAMap from 'vue-amap'
Vue.use(VueAMap)

VueAMap.initAMapApiLoader({
  key: '7a13c5835f06fc620945ab2b22b17aac',
  // ui/misc/PoiPicker
  plugin: ['AMap.Scale', 'AMap.AdvancedInfoWindow', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PlaceSearch', 'AMap.Geolocation', 'AMap.Geocoder'],
  v: '1.4.4',
})

// const originalReplace = router.prototype.replace;
// router.prototype.replace = function replace(location) {
//     return originalReplace.call(this, location).catch(err => err);
// };

new Vue({
	router,
	i18n,
	render: h => h(App),
}).$mount('#app')
