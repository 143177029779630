<template>
   <div id="app">
      <router-view />
    </div>
</template>

<script>
export default {
  name: 'app',
}
</script>

<style>
	@import './assets/uni.css';	
	@import "./assets/yx-main.css";
html, body{
	margin: 0;
	height: 100%;
}	
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #e0e0e0;
  background-color: #FFFFFF;
  min-height: 100%;
}
</style>
