const tw = {
	header: {
		logo: "標誌",
		router: {
			index: "首頁",
			about: "關於",
			team: "團隊",
			whitePaper: '白皮書',
			service: '服務',
			language: '語言',
			download: 'app下載'
		}
	},
	download: {
		pc: {
			download: '下載APP用戶端',
			title: '隨時管理你的數位資產',
			bnt1: 'IOS版本',
			bnt2: 'Android版本',
			qrcode: '掃描二維碼下載APP',
			tutorial: 'iOS用戶安裝教程',
			title1: '第一步',
			Subheading1: '下載APP',
			content1: '在手機中打開設定功能,選擇“通用”',
			title2: '第二步',
			Subheading2: '設備管理',
			content2: '在通用中,選擇“描述檔案與設備管理”功能',
			title3: '第三步',
			Subheading3: '選擇安裝',
			content3: '在描述檔案與設備管理中的企業級應用分類中,選擇要安裝的企業應用的檔案名稱（與打開時的提示一致）,點擊進入',
			title4: '第四步',
			Subheading4: '設定信任',
			content4: '進入企業簽名頁面後,確認企業簽名中的公司名稱與應用名稱後,點擊信任“企業簽名公司名稱”',
			title5: '第五步',
			Subheading5: '啟動APP',
			content5: '回到案頭,重新打開應用即可使用。',
		}
	},
	user: {
		login: "登錄",
		register: "註冊",
		hasCount: "已有賬號?",
		noCount: "沒有賬號?",
		logOut: "退出登錄",
		logSucc: "登錄成功",
		place: {
			userName: "請輸入用戶名",
			passWord: "請輸入密碼",
			passWordAgain: "請再次輸入密碼",
			passNotMatch: "兩次輸入密碼不壹致",
			leader: '請輸入邀請人',
			Agreement: '用戶協議',
			agreeAgreement: '請先同意用戶協議'
		}
	},
	mnemonic: {
		myWord: "我的助記詞",
		hint: "請將它準確的抄寫在紙上,並存在只有妳知道的安全地方",
		next: "下壹步",
		backup: "備份助記詞",
		hint2: "請按順序點擊妳的助記詞，以便確認備份助記詞正確",
		clean: "清 除",
		select: "請選擇",
		finish: "完 成",
		notify1: "助記詞選擇錯誤，請重新再來!",
		notify2: "助記詞備份完成，請下載app！"
	},
	code: {
		error: "錯誤"
	},
	expression: {
		regSuccess: '註冊成功，請下載app進行下壹步操作！',
		swipetext1: '電子商務的新標準從LUCY開始',
		swipetext2: 'LUCY代幣被計劃成為新電子商務應用程序和市場中使用',
		swipetext3: 'LUCY將協助商家更方便更快捷支付，並從他們的社區中獲得建設性的參與',
		aboutText: '電子商務鏈（LUCY）是General Services Administration（GSA）針對電商發行的區塊鏈支付系統，成立於1949年的GSA全稱為General ServicesAdministration，總務管理局是美國政府中壹個獨立的機構，負責管理各聯邦機構的各項事務管理，其中，GSA下面最大的業務部門是聯邦采購服務所（Federal Acquisition Service）和公共建築服務所（Public Buildings Service），LUCY壹種將區塊鏈技術集成到電子商務和在線市場的協議。該協議將為解決當前電子商務平臺的問題提供“智能電子商務合同”。LUCY代幣被計劃成為新電子商務應用程序和市場中使用。LUCY將協助商家更方便更快捷支付，並從他們的社區中獲得建設性的參與。通過這種方式，LUCY創建了壹個公共網絡，為其用戶提供可信和安全的業務服務。它將把現有的電子商務市場與區塊鏈協議結合起來，為其用戶提供平臺。這種技術集成增加了壹層事務設施和不可變的分類安全，這是目前無與倫比的。這有助於引入額外的特性和應用程序。電子商務的新標準從LUCY開始。',
		downloadText: '這是壹個概念性文檔（"白皮書"），描述了電子商務鏈（LUCY）平臺及其LUCY標記的概念。它可以在任何時間隨時修改或更換。但是，LUCY沒有義務更新白皮書或向接收方提供訪問任何附加信息的權限。',
		novelList1: {
			title: '什麽是LUCY？',
			content: "通過專註於電子商務和在線市場，LUCY將整合最新的區塊鏈創新，在壹個高效的鏈條上為“ 智能電子商務合同” 提供動力， 並解決與現有的、 通用的區塊鏈相關的延遲問題。 它的實用令牌， LUCY令牌， 被計劃成為壹個“ 主令牌”， 用於資助和授權新的電子商務應用程序和項目。 在整個網絡開發過程中， LUCY將協助分散平臺的治理， 並由生態系統成員的大力參與。 因此， 創建壹個提供安全可靠業務的公共網絡， 為其成員提供安全可靠的業務服務。",
		},
		novelList2: {
			title: '新壹代電子商務區塊鏈和電子商務',
			content: '有了LUCY平臺，區塊鏈網絡中的所有參與節點都維護了驗證信息，並確保信息的壹致性。因此，在區塊鏈支付中不需要復雜的信息同步和和解。這大大提高了全球電子商務支付的效率。'
		},
		novelList3: {
			title: 'LUCY的價值…',
			content: 'LUCY代幣是下壹個電子商務鏈的原生代幣。LUCY代幣是該鏈生態系統的重要組成部分。它們保證了電子商務市場的收入流入。這種保證是由於消費和交易所需的過程而獲得的。LUCY總發行量33億枚。首次發行3000萬作為節點合夥人，為了獲得更多貨幣，只有通過消費和交易挖礦作為獎勵。每交易所得LUCY數量5%自然銷毀，預計在30年全部釋放，銷毀後的LUCY數量總計不足壹億枚，升值潛力巨大，貨幣持有者還能從下壹個電子商務鏈中獲得額外的好處。',
		},
		novelList4: {
			title: 'LUCY消費交易挖礦',
			content: 'LUCY最大的亮點是直接應用，客戶從全球聯盟各大電商平臺消費中獲得礦池，消費越多礦池儲量越大，從每天礦池溢出的數量可以通過在交易所全球節點合夥人買入LUCY，按照1：10將礦池溢出的數量帶出，時間約壹周左右，每次挖礦獲得LUCY數量的5%自然銷毀，總量越來越少。',
		},
		novelList5: {
			title: '利潤分享',
			content: 'LUCY的活躍客戶將獲得購買的信用額度。然後，他們可以使用這些來購買商品或交易LUCY。壹旦他們開始交易並開始挖礦，他們自然會成為這個鏈條的成員。當這些現有成員邀請更多的新成員加入該平臺時，且這些新成員成為正式成員積累了壹定數量的信用，原成員將成為LUCY的合作夥伴。這個過程會發生變化，並且只是“合作夥伴進程”的最初提議。“從成員到合作夥伴的正式程序將在平臺的使用條款中詳細說明，供用戶進壹步理解並受益於LUCY。',
		},
		teamList1: {
			name: '艾伯特邁爾斯-邁克爾',
			position: '副總裁',
			content: '在區塊鏈技術領域壹直是壹個狂熱的投資者，已經有8年了。 在開始管理家族的房地產公司後， 他花了5年時間來監督這些業務。 從那時起， 他決定最好自己開壹家公司。 那是在2008年，到2009年， 他是開發比特幣錢包blockchain.info的創始合夥人之壹， 他很快就籌集到了足夠的資金和知名度。 現在他是LUCY項目的關鍵顧問。',
		},
		teamList2: {
			name: 'Norman Liu',
			position: 'CFO',
			content: '諾曼在加入LUCY團隊時是Coindesk的首席財務官。他是30多歲的狂熱的風險投資人，他在下壹代電子商務平臺上看到了巨大的潛力。很快，他投資了30%的種子資金來啟動這個項目。現在，他是壹個重要的董事會成員和附屬項目的顧問。'
		},
		teamList3: {
			name: 'Jack Cheng',
			position: 'CTO',
			content: '最初是大多數LUCY區塊鏈的後端開發人員，傑克現在領導著30多名工程師的團隊。他確保總有專業人員可以使用任何bug，或者對系統進行必要的調整。'
		},
		teamList4: {
			name: 'Miyuki Saeiki',
			position: 'CPO',
			content: '是整個亞洲市場的首席公關總監，鳩山幸與區塊鏈技術的頂級賽事聯系在壹起，領導了路演團隊。他的介紹給這個平臺帶來了成千上萬的新客戶。'
		},
		teamList5: {
			name: 'Patrick Wang',
			position: 'CCO',
			content: '最後壹個核心團隊成員，Patrick對最新的市場營銷技巧有了新的見解。現在，他領導了我們的整個在線活動，並監控我們所有的社交媒體賬戶。'
		}
	},
	home: {
		'下载白皮书': '下載白皮書',
		'新一代电子商务': '新壹代電子商務',
		'核心团队和贡献者': '核心團隊和貢獻者',
		'免责声明': '免責聲明',

	}
};
export default tw;
