const cn = {
	header: {
		logo: "标志",
		router: {
			title: "哟西",
			publish: "官方发布",
			about: "关于YXC",
			info: "资讯",
			project: '项目进展',
			language: '语言',
			plan: '项 目 规 划',
		}
	},
	download: {
		pc: {
			download: '下载APP客户端',
			title: '随时管理你的数字资产',
			bnt1: 'IOS 版本',
			bnt2: 'Android 版本',
			qrcode: '扫描二维码下载	APP',
			tutorial: 'iOS 用户安装教程',
			title1: '第一步',
			Subheading1: '下载APP',
			content1: '在手机中打开设置功能，选择"通用"',
			title2: '第二步',
			Subheading2: '设备管理',
			content2: '在通用中，选择"描述文件与设备管理"功能',
			title3: '第三步',
			Subheading3: '选择安装',
			content3: '在描述文件与设备管理中的企业级应用分类中， 选择要安装的企业应用的文件名称（ 与打开时的提示一致），	点击进入 ',
			title4: '第四步',
			Subheading4: '设置信任',
			content4: '进入企业签名页面后，确认企业签名中的公司名称与应用名称后，点击信任"企业签名公司名称"',
			title5: '第五步',
			Subheading5: '启动APP',
			content5: '回到桌面，重新打开应用即可使用。',
		},
	},
	user: {
		login: "登录",
		register: "注册",
		hasCount: "已有账号?",
		noCount: "没有账号?",
		logOut: "退出登录",
		logSucc: "登录成功",
		place: {
			userName: "请输入用户名",
			passWord: "请输入密码",
			passWordAgain: "请再次输入密码",
			passNotMatch: "两次输入密码不一致",
			leader: '请输入邀请人',
			Agreement: '用户协议',
			agreeAgreement: '请先同意用户协议'
		}
	},
	mnemonic: {
		myWord: "我的助记词",
		hint: "请将它准确的抄写在纸上，并存在只有你知道的安全地方",
		next: "下一步",
		backup: "备份助记词",
		hint2: "请按顺序点击你的助记词，以便确认备份助记词正确",
		clean: "清 除",
		select: "请选择",
		finish: "完 成",
		notify1: "助记词选择错误，请重新再来!",
		notify2: "助记词备份完成，请下载app！"
	},
	code: {
		error: "错误"
	},
	expression: {
		regSuccess: '注册成功，请下载app进行下一步操作！',
		swipetext1: '传统很多商业巨头的崛起和收益由用户创造',
		swipetext2: '但用户却不能分享收益',
		swipetext3: '未来因用户而崛起却没有收益的时代终将过去',
		swipetext4: '---来自哟西生态的愿景',
		videoIntro: '视 频 介 绍',
		
		aboutText1: '互联网高速发展的时代不断改革创新，商业巨头占据用户，实现了很大盈利，但用户无法参与收益，这是一种非常不公平的现象！',
		aboutText2: '而新加坡YXC FOUNDATION LTD致力于打造基于区块链最大哟西APP落地生态！将实现用户崛起，开创区块链实质性落地新时代！',
		aboutText3: 'YXC基金会、哟西项目技术团队成员共计52名，大多来自于前阿里巴，百度工程师，拥有完整的开发落地经验。',
		aboutText4: 'YXC在哟西APP生态作为通证使用，通过视频、社区、社交、挖矿、投票、打赏、项目孵化、支付等场景流通燃烧使用， 用户邀请新用户便可参与未来收益。',
		aboutText5: '用户使用APP所有板块如发布原创视频，社区发布文章等， 被喜欢的用户点赞或被打赏都将形成收益！让用户实现付出和贡献的对等价值。',
		aboutText6: '将实现用户崛起与共享收益，开创实质性落地的新时代。',
		
		infoText1: '区块链资讯频道，带您了解区块链是什么，为您报道最新的区块链行业新闻，最新的区块链信息，最新的区块链资讯动态。',
		
		imageText: '哟西生态优势',
		imageText1: '一个真正自己的平台,每个人都是平台的主人, ',
		imageText2: '有付出就有回报——通过邀请就可以获得这个平台的收益！',
		
		planText1: '2021待续...',
		
		mediaText1: '拥有火力覆盖全球宣发的能力',
		mediaText2: '海内外头部媒体全覆盖、宣传精准、高效',
		
		downloadText1: '白皮书下载',
		downloadText2: 'APP下载',
		
		downloadText: '这是一个概念性文档（"白皮书"），描述了电子商务链（LUCY）平台及其LUCY标记的概念。它可以在任何时间随时修改或更换。但是，LUCY没有义务更新白皮书或向接收方提供访问任何附加信息的权限。',
		
		year: '年',
		month: '月',
		projectText: '项目进展中，请稍候...',
		projectText1: '1月进展',
		projectText2: '2月进展',
		projectText3: '3月进展',
		projectText4: '4月进展',
		projectText5: '5月进展',
		projectText6: '6月进展',
		projectText7: '7月进展',
		projectText8: '8月进展',
		projectText9: '9月进展',
		projectText10: '10月进展',
		projectText11: '11月进展',
		projectText12: '12月进展',
		
	},
	home: {
		'下载白皮书': '下载白皮书',
		'新一代电子商务': '新一代电子商务',
		'核心团队和贡献者': '核心团队和贡献者',
		'免责声明': '免责声明',

	}
};
export default cn;
