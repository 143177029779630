import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const routes = [
	{
		name: 'home',
		component: () => import('./view/home/home'),
		meta: {
			title: '宁德市千雪制冷设备有限公司'
		}
	},
	{
		name: 'about',
		component: () => import('./view/about/about'),
		meta: {
			title: '关于我们'
		}
	},
	{
		name: 'news',
		component: () => import('./view/news/news'),
		meta: {
			title: '新闻资讯'
		}
	},
	{
		name: 'newsDetail',
		component: () => import('./view/news/newsDetail'),
		meta: {
			title: '新闻资讯'
		}
	},
	{
		name: 'case',
		component: () => import('./view/case/case'),
		meta: {
			title: '工程案例'
		}
	},
	{
		name: 'contact',
		component: () => import('./view/contact/contact'),
		meta: {
			title: '联系我们'
		}
	},
	{
		name: 'service',
		component: () => import('./view/service/service'),
		meta: {
			title: '售后服务'
		}
	},
	{
		name: 'process',
		component: () => import('./view/process/process'),
		meta: {
			title: '合作流程'
		}
	},
	{
		name: 'product',
		component: () => import('./view/product/product'),
		meta: {
			title: '产品中心'
		}
	},
	{
		path: '*',
		redirect: '/home'
	},
	
];

// add route path
routes.forEach(route => {
	route.path = route.path || '/' + (route.name || '');
});

const router = new Router({
	routes
});

router.beforeEach((to, from, next) => {
	const title = to.meta && to.meta.title;
	if (title) {
		document.title = title;
	}
	next();
});

export {
	router
};
